/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    top: theme.spacing(2),
    margin: "auto",
    padding:theme.spacing(10)
  },
 image:{
   maxWidth:200,
   maxHeight:200,
    marginLeft: "auto",
    marginRight: "auto"
  },
text:{
  marginTop: theme.spacing(5),
     marginLeft: "auto",
     marginRight: "auto"
   },
  underlineNone: {
    textDecoration: "none",  
    color: "black"
  }
}));
const Bio = (props) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            vimeo
          }
        }
      }
    }
  `)


  const classes = useStyles();
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social
  const avatar = data?.avatar?.childImageSharp?.fluid
  
  return (
    <div  className={classes.root} >
      <div  className={classes.image}>
      {avatar && (
        <Image 
        
        fluid={avatar}
        width={100}
          alt={author?.name || ``}
          className="bio-avatar"
          objectPosition="50% 50%"
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
      )}
      </div>
      <div  className={classes.text}>
        {author?.name && (
          <p>
           {author?.summary || null}
            {` `}
            <a  href={`https://vimeo.com/${social?.vimeo || ``}`}>
             Vimeo
            </a>
          </p>
        )}

       </div>
    </div>
   
  )
}

export default Bio
